.custom-checkbox {
    cursor: pointer;
    input[type='checkbox'] {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    .checkbox {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: transparent;
        border: 1px #ddd solid;
        border-radius: 4px;
        margin-right: 4px;
    }
}
