.about {
    max-width: var(--container-max-width);
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .table-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 4rem;
        .section {
            cursor: pointer;
        }
    }

    .section {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 1rem;

        .logo {
            flex: 1;
            img {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }

        .text {
            text-align: justify;
            font-size: 1rem;
            flex: 4;
        }
    }
}
