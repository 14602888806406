:root {
    --navmenu-height: 64px;
    --container-top-padding: 16px;
    --container-max-width: 1080px;
    --gnogen-orange: #f68920;
    --gnogen-orange-light: #eea866;
    --bg-background: #fffdf6;
}

.App {
    //text-align: center;
    min-height: 100vh;
}

@font-face {
    font-family: 'Gnogen';
    src: local('Gnogen'), url(./assets/fonts/gnogen.otf) format('opentype');
}

@font-face {
    font-family: 'Gnogen Light';
    src: local('Gnogen Light'),
        url(./assets/fonts/gnogen-light.otf) format('opentype');
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'),
        url(./assets/fonts/Roboto-Light.ttf) format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'),
        url(./assets/fonts/Roboto-Regular.ttf) format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'),
        url(./assets/fonts/Roboto-Medium.ttf) format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(./assets/fonts/Roboto-Bold.ttf) format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'),
        url(./assets/fonts/Roboto-Black.ttf) format('truetype');
    font-weight: 900;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), url(./assets/fonts/Lato-Regular.ttf) format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), url(./assets/fonts/Lato-Bold.ttf) format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'),
        url(./assets/fonts/Montserrat-VariableFont_wght.ttf) format('truetype');
}

@font-face {
    font-family: 'Halcom';
    src: local('Halcom'),
        url(./assets/fonts/Halcom-Regular.otf) format('opentype');
    font-weight: 400;
}

@font-face {
    font-family: 'Halcom';
    src: local('Halcom'),
        url(./assets/fonts/Halcom-Medium.otf) format('opentype');
    font-weight: 500;
}

.scrollbar-hidden {
    &::-webkit-scrollbar {
        display: none;
    }
}

.text-border {
    -webkit-text-stroke: 1px black;
}

.text-shadow {
    text-shadow: 1px 3px 5px #221f1faa;
}

.text-shadow-light {
    text-shadow: 1px 1px 3px #221f1faa;
}
.text-shadow-white {
    text-shadow: 1px 1px 3px #ffffff66;
}

.text-vertical {
    writing-mode: vertical-rl;
    text-orientation: mixed;
}

.animated-all {
    transition: 0.15s;
}

.animated-fill {
    transition: fill 0.15s ease-in-out;
}

.animated-box-shadow {
    transition: box-shadow 0.15s ease-in-out;
}

.animated-backdrop {
    transition: backdrop-filter 0.15s ease-in-out;
}

.animated-border {
    transition: border-color 0.15s ease-in-out;
    transition: border-width 0.15s ease-in-out;
}

.animated-background {
    transition: background-color 0.15s ease-in-out;
}

.animated-opacity {
    transition: opacity 0.15s ease-in-out;
}

.animated-text {
    transition: font-size 0.15s ease-in-out, color 0.15s ease-in-out;
}

.animated-transform {
    -webkit-transition: -webkit-transform 0.15s ease-in-out;
    -ms-transition: -ms-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out;
}

.animated-transform-fast {
    -webkit-transition: -webkit-transform 0.05s ease-in-out;
    -ms-transition: -ms-transform 0.05s ease-in-out;
    transition: transform 0.05s ease-in-out;
}

.animated-padding {
    transition: padding 0.15s ease-in-out;
}

.fadeInDownLight {
    animation-name: fadeInDownLight;
}

.max-w-8xl {
    max-width: 88rem;
}

.max-w-9xl {
    max-width: 96rem;
}

.max-w-10xl {
    max-width: 104rem;
}

.max-w-11xl {
    max-width: 112rem;
}

.max-w-12xl {
    max-width: 120rem; /* 1792px */
}

.text-10xl {
    font-size: 10rem; /* 1792px */
}

@keyframes fadeInDownLight {
    from {
        opacity: 0;
        transform: translate3d(0, -10%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes glow {
    from {
        text-shadow: 0 0 0.15rem #fff, 0 0 0.3rem #fff, 0 0 0.45rem #f68920;
    }
    to {
        text-shadow: 0 0 0.3rem #fff, 0 0 0.45rem #eea866, 0 0 0.6rem #eea866;
    }
}

@-webkit-keyframes glow {
    from {
        text-shadow: 0 0 0.15rem #fff, 0 0 0.3rem #fff, 0 0 0.45rem #f68920;
    }
    to {
        text-shadow: 0 0 0.3rem #fff, 0 0 0.45rem #eea866, 0 0 0.6rem #eea866;
    }
}
